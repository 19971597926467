@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Work Sans" Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-optical-sizing: auto;
  font-style: normal;
  background-color: #f9f9f9;
}



